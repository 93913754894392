body {
}

.transparenttabs {
    background-color: transparent !important;
}
.transparenttabs > div{
    background-color: transparent !important;
}

.tablestyle__accordion > div > table {
    border-collapse: separate;
    border-spacing: 0 15px !important;
}

.tablestyle__accordion > div > table > tbody > tr > td{
    background-color: #0c733b;
    color: white;
}

.tablestyle__accordion > div > table > tbody > tr > td > *{
    padding-bottom: 10px;
    padding-top: 10px;
    height: 40px;

}

.tablestyle__accordion > div > table > tbody > tr:hover > td{
    background-color: #1F7E4B !important;
}

.tablestyle__accordion > div > table > tbody > tr > td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.tablestyle__accordion > div > table > tbody > tr > td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.readonly-wrapper{
    margin-top: 2px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    padding-top: 6px;
}

.readonly-wrapper__label{
    transform: scale(0.75);
    transform-origin: top left;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow-wrap: break-word;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    pointer-events: auto;
    tab-size: 4;
    text-align: left;
    text-rendering: optimizelegibility;
    white-space: nowrap;
    word-break: normal
}

.readonly-wrapper__value{
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
}

.download-file{
    width: fit-content !important;
    display: flex;
    justify-content: start;
    align-content: center;
    padding: 4px;
    padding-right: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all ease-in-out 120ms;
}

.download-file:hover{
    background-color: #1F7E4B;
}
.download-file:hover > *{
    color: white !important;
}

.chevron-expander{
    transition: all ease-in-out 250ms;
    transform: rotate(-90deg);
    padding-bottom: 5px;
}

.chevron-expander__open{
    transform: rotate(0) translateY(4px);
}

.my-dossiers-table__non-mobile-row {
    display: table-row;
}

.my-dossiers-table__mobile-row {
    display: none;
}

.table-expand__non-mobile{
    display: table-cell;
}

.table-expand__mobile{
    display: none;
}

@media screen and  (max-width: 600px) {
    .my-dossiers-table__non-mobile-row {
        display: none !important;
    }

    .my-dossiers-table__mobile-row {
        display: table-row !important;
    }

    .tablestyle__accordion > div > table > tbody > tr > td:first-child {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .tablestyle__accordion > div > table > tbody > tr > td:last-child {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .table-expand__non-mobile{
        display: none !important;
    }

    .table-expand__mobile{
        display: table-cell !important;
    }
}

.text-dots{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.text-dots *{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.bar-switcher{
    width: 100% !important;

}

.bar-switcher .v-btn__content{
    width: 100% !important;
}

.bar-switcher .v-btn--text{
    width: 100% !important;
}

.admintoggle{
    display: flex;
    justify-content: right;
    align-items: center;
    cursor: pointer;
    transition: all 150ms ease-in-out;
}
.admintoggle > *{
    transition: all 150ms ease-in-out;
    pointer-events: none;
}
.admintoggle:hover{
    background-color: #f6f6f6;
}
.admintoggle:hover > *{
    background-color: #f6f6f6;

}

.admintoggle_activate:hover > *{
    color: red !important;
}
.admintoggle_deactivate:hover > *{
    color: #0c733b !important;
}

.treecheckbox .v-input__control .v-messages{
    display: none !important;
}

.v-data-table__mobile-row{
    align-items: start !important;
    display: flex;
    justify-content: space-between;
}
