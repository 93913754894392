











































































































































































































































.activeTab {
  background-color: #0c733b;
  color: white;
}
.tab {
  max-width: 250px !important;
  display: inline-block;
  vertical-align: center;
}
.tab_empty {
  max-width: fit-content !important;
  display: inline-block;
  vertical-align: center;
}
.tab p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
}
