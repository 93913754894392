















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.step-header {
  margin: 0 !important;
  padding: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.step-header__active {
  font-weight: 700 !important;
}
.v-stepper__step--active {
  background-color: rgba(12, 115, 59, 0.1) !important;
  border-radius: 5px;
}
.header__inprogress {
}

.v-stepper__header {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start !important;
}
.v-stepper__header > * {
  flex-shrink: 1;
  min-width: 0;
}

.v-stepper__step {
  overflow: hidden;
}
