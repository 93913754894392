





























































































































$hoverColor: rgba(240, 240, 240);

.action-tile__call-to-action {
  border: 4px dashed lightgrey;
  width: 100%;
}

.action-tile__is-dragover {
  background: $hoverColor;
}

.action-tile {
  cursor: pointer;

  &:hover {
    background: $hoverColor;
  }

  &.action-tile--disabled {
    cursor: no-drop;
    background: lightgrey;

    &:hover {
      background: lightgrey;
    }
  }
}
